@import "~@elliemae/ds-basic/css/dimsum.css";

.root-container {
  .em-ds-modal-slide__wrapper {
    position: fixed;
    .em-ds-modal-slide__overlay {
      background-color: rgba(0, 0, 0, 0);
      animation: none;
      .em-ds-modal-slide__content {
        max-width: 522px;
      }
    }
  }
}

.em-ds-labelValue__wrapper .em-ds-icon {
  margin-top: 0;
  fill: color(brand-primary, 600);
  height: auto;
  margin-right: 0.30769rem;
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;
}

div[data-testid="ds-tooltip-v3-tooltip-text-wrapper"] > div {
  max-width: 600px;
}

div[data-testid="ds-tooltip-v3-trigger-wrapper"] {
  width: fit-content;
}

div[data-testid="data-table-row"] {
  z-index: auto !important;
}

label {
  width: fit-content;
}
